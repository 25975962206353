html {
  overflow-x: hidden;
}

html::-webkit-scrollbar {
  width: 10px;
}

html::-webkit-scrollbar-track {
  background: #e6e6e6;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

html::-webkit-scrollbar-thumb {
  background: #a3151c;
  border: 0;
  cursor: pointer !important;
  border-radius: 10px;
}

.sidenav::-webkit-scrollbar {
  width: 7px;
}

.sidenav::-webkit-scrollbar-track {
  background: #e6e6e6;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.sidenav::-webkit-scrollbar-thumb {
  background: #a3151c;
  border: 0;
  cursor: pointer !important;
  border-radius: 10px;
}

.sidebar-category::-webkit-scrollbar {
  width: 7px;
}

.sidebar-category::-webkit-scrollbar-track {
  background: #e6e6e6;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.sidebar-category::-webkit-scrollbar-thumb {
  background: #a3151c;
  border: 0;
  cursor: pointer !important;
  border-radius: 10px;
}

.col,
.col-12,
.table,
legend {
  max-width: 100%;
}

.page-image,
img {
  object-fit: contain;
}

body {
  font-size: 1.1rem;
  line-height: 1.4;
  background: #fff;
  outline: 0;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
}

.flickity-enabled.is-draggable,
html {
  -webkit-tap-highlight-color: transparent;
}

button,
hr,
input {
  overflow: visible;
}

.collapse.show,
.no-js .owl-carousel,
.owl-carousel.owl-loaded,
.widget-socials .socials,
article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
legend,
main,
nav,
section {
  display: block;
}

address,
legend {
  line-height: inherit;
}

progress,
sub,
sup {
  vertical-align: baseline;
}

.container,
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

.btn-button.btn-wide,
.btn-wide,
.container,
.img-fullwidth,
.table,
body,
img,
input,
legend,
select,
textarea,
video {
  width: 100%;
}

.table,
a {
  background-color: transparent;
}

.alert,
.btn,
.btn i,
.collapsing,
.flickity-enabled,
.relative,
.search-form,
blockquote p,
sub,
sup {
  position: relative;
}

.modal-open .modal,
body {
  overflow-x: hidden;
  overflow-y: auto;
}

body,
video {
  height: 100%;
}

dd,
h1,
h2,
h3,
h4,
h5,
h6,
legend {
  margin-bottom: 0.3rem;
}

dl,
ol,
p,
pre,
ul {
  margin-top: 0;
}

pre,
textarea {
  overflow: auto;
}

html,
pre {
  -ms-overflow-style: scrollbar;
}

body,
p {
  color: #444;
}

[type="search"],
button.close,
select {
  -webkit-appearance: none;
}

.clearfix,
body {
  zoom: 1;
}

.pagination,
.sidenav__menu ul,
ul {
  list-style: none;
}

.btn,
.button-1,
.newsticker__item,
.sr-only {
  white-space: nowrap;
}

@media print {
  blockquote,
  img,
  pre,
  tr {
    page-break-inside: avoid;
  }

  *,
  :after,
  :before {
    text-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  blockquote,
  pre {
    border: 1px solid #999;
  }

  thead {
    display: table-header-group;
  }

  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }

  .container,
  body {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #121212;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td,
  .table th {
    background-color: #fff !important;
  }

  .table-bordered td,
  .table-bordered th {
    border: 1px solid #ddd !important;
  }

  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

*,
:after,
:before,
input[type="search"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  overflow-x: hidden;
}

@-ms-viewport {
  width: device-width;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}

abbr[data-original-title],
abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

#back-to-top,
.btn,
.button-1,
.close:focus,
.close:hover,
.nav--sticky.sticky .nav__menu > li > a,
.page-link:hover,
a,
a:focus,
a:hover {
  text-decoration: none;
}

.alert,
.table,
dl,
ol,
pre,
ul {
  margin-bottom: 1rem;
}

body,
fieldset,
ul {
  margin: 0;
}

.widget .entry:last-child,
ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}

.alert-link,
.btn,
.close,
.social__text,
.widget-title,
dt,
kbd kbd,
label {
  font-weight: 600;
}

.no-gutters,
.page-item:first-child .page-link,
dd {
  margin-left: 0;
}

blockquote {
  margin: 40px 0;
  text-align: center;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

.sidenav__menu-dropdown a,
blockquote cite {
  font-weight: 400;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
}

.btn,
img {
  vertical-align: middle;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

.btn-light,
.btn-stroke,
.btn-white,
a,
label {
  color: #121212;
}

a:not([href]):not([tabindex]),
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}

.flickity-enabled:focus,
.owl-custom-nav__btn:focus,
a:not([href]):not([tabindex]):focus,
input[type="radio"]:focus {
  outline: 0;
}

code,
kbd,
pre,
samp {
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img {
  border-style: none;
  border-radius: 5px;
  height: auto;
}

figure figcaption {
  width: 100% !important;
  margin-bottom: 10px !important;
}

.marquee-container {
  width: auto !important;
}

.btn,
.modal-open,
.newsticker,
.oh,
.sr-only,
.trending-now,
svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  caption-side: bottom;
}

.alert-heading,
a > code,
h1 > a,
h2 > a,
h3 > a,
h4 > a,
h5 > a,
h6 > a,
legend,
pre code {
  color: inherit;
}

th {
  text-align: inherit;
}

button {
  border-radius: 0;
}

button:focus {
  outline: dotted 1px;
  outline: -webkit-focus-ring-color auto 5px;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[type="reset"],
[type="submit"],
button,
html [type="button"] {
  -webkit-appearance: button;
}

[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="time"] {
  -webkit-appearance: listbox;
}

fieldset {
  min-width: 0;
  padding: 0;
  border: 0;
}

legend {
  padding: 0;
  font-size: 1.5rem;
  white-space: normal;
}

.loader > div,
output {
  display: inline-block;
}

code,
kbd,
pre {
  font-size: 87.5%;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

.youtubeicon {
  position: absolute;
  left: 15%;
  top: 15%;
  height: 40px;
  width: 40px;
  cursor: pointer;
}

summary {
  display: list-item;
  cursor: pointer;
}

.collapse,
.onlyDesk,
template {
  display: none;
}

.d-none,
[hidden] {
  display: none !important;
}

code {
  color: #e83e8c;
  word-break: break-word;
}

kbd {
  padding: 0.2rem 0.4rem;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
}

pre {
  display: block;
  color: #212529;
}

pre code {
  font-size: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.col-1,
.col-10,
.col-11,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto {
  -webkit-box-flex: 0;
}

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  -ms-flex: 0 0 8.33333%;
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  -ms-flex: 0 0 41.66667%;
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 58.33333%;
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.33333%;
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  -ms-flex: 0 0 91.66667%;
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}

.order-first {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -webkit-box-ordinal-group: 14;
  -ms-flex-order: 13;
  order: 13;
}

.order-0 {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}

.order-4 {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4;
}

.order-5 {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order: 5;
  order: 5;
}

.order-6 {
  -webkit-box-ordinal-group: 7;
  -ms-flex-order: 6;
  order: 6;
}

.order-7 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 7;
  order: 7;
}

.order-8 {
  -webkit-box-ordinal-group: 9;
  -ms-flex-order: 8;
  order: 8;
}

.order-9 {
  -webkit-box-ordinal-group: 10;
  -ms-flex-order: 9;
  order: 9;
}

.order-10 {
  -webkit-box-ordinal-group: 11;
  -ms-flex-order: 10;
  order: 10;
}

.order-11 {
  -webkit-box-ordinal-group: 12;
  -ms-flex-order: 11;
  order: 11;
}

.order-12 {
  -webkit-box-ordinal-group: 13;
  -ms-flex-order: 12;
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.btn-white,
.table .table {
  background-color: #fff;
}

.table-sm td,
.table-sm th {
  padding: 0.3rem;
}

.table-bordered,
.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}

.table .thead-dark th,
.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #32383e;
}

.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-active,
.table-active > td,
.table-active > th,
.table-hover .table-active:hover,
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th,
.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > td,
.table-primary > th {
  background-color: #b8daff;
}

.table-hover .table-primary:hover,
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > td,
.table-secondary > th {
  background-color: #d6d8db;
}

.table-hover .table-secondary:hover,
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > td,
.table-success > th {
  background-color: #c3e6cb;
}

.table-hover .table-success:hover,
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > td,
.table-info > th {
  background-color: #bee5eb;
}

.table-hover .table-info:hover,
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > td,
.table-warning > th {
  background-color: #ffeeba;
}

.table-hover .table-warning:hover,
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > td,
.table-danger > th {
  background-color: #f5c6cb;
}

.table-hover .table-danger:hover,
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > td,
.table-light > th {
  background-color: #fdfdfe;
}

.table-hover .table-light:hover,
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > td,
.table-dark > th {
  background-color: #c6c8ca;
}

.table .thead-dark th,
.table-dark {
  color: #fff;
  background-color: #212529;
}

.table-hover .table-dark:hover,
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.btn,
.table-dark.table-bordered,
.table-responsive > .table-bordered,
hr {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.media,
.modal-content,
.modal-dialog-centered,
.modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
}

.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}

.fade.show,
.flickity-page-dots .dot.is-selected,
.owl-carousel:hover .owl-next,
.owl-carousel:hover .owl-prev {
  opacity: 1;
}

tr.collapse.show {
  display: table-row;
}

tbody.collapse.show {
  display: table-row-group;
}

.collapsing {
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s;
  -o-transition: height 0.35s;
  transition: height 0.35s;
}

.alert {
  padding: 0.75rem 1.25rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

.media {
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.media-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.close {
  float: right;
  font-size: 1.5rem;
  line-height: 1;
  color: #121212;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.close:focus,
.close:hover {
  color: #121212;
  opacity: 0.75;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  -o-transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  transform: translate(0, -25%);
}

.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-dialog-centered {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}

.modal-content {
  position: relative;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.flex-column,
.flex-row {
  -webkit-box-direction: normal !important;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #121212;
}

.modal-backdrop.fade,
.owl-carousel.owl-hidden {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}

.sr-only,
body,
ul {
  padding: 0;
}

.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.last,
.mb-0,
.my-0,
blockquote p {
  margin-bottom: 0 !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive:before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9:before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9:before {
  padding-top: 56.25%;
}

.embed-responsive-4by3:before {
  padding-top: 75%;
}

.embed-responsive-1by1:before {
  padding-top: 100%;
}

.flex-row {
  -webkit-box-orient: horizontal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-column-reverse,
.flex-row-reverse {
  -webkit-box-direction: reverse !important;
}

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  -webkit-clip-path: none;
  clip-path: none;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100,
figure img {
  width: 100% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }

  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .order-sm-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }

  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }

  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }

  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }

  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }

  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }

  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.66667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333%;
  }

  .offset-sm-5 {
    margin-left: 41.66667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333%;
  }

  .offset-sm-8 {
    margin-left: 66.66667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333%;
  }

  .offset-sm-11 {
    margin-left: 91.66667%;
  }

  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }

  .modal-sm {
    max-width: 300px;
  }

  .d-sm-none {
    display: none !important;
  }

  .d-sm-block {
    display: block !important;
  }
}

.mb-40,
.page-featured-img,
.section,
.sidebar .widget {
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }

  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .order-md-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }

  .order-md-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .order-md-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .order-md-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .order-md-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  .order-md-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  .order-md-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  .order-md-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  .order-md-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }

  .order-md-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }

  .order-md-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }

  .order-md-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }

  .order-md-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }

  .order-md-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.66667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333%;
  }

  .offset-md-5 {
    margin-left: 41.66667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333%;
  }

  .offset-md-8 {
    margin-left: 66.66667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333%;
  }

  .offset-md-11 {
    margin-left: 91.66667%;
  }

  .d-md-none {
    display: none !important;
  }

  .d-md-block {
    display: block !important;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }

  .paddingRightMd15 {
    padding-right: 15px;
  }

  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .order-lg-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }

  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }

  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }

  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }

  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }

  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }

  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.66667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333%;
  }

  .offset-lg-5 {
    margin-left: 41.66667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333%;
  }

  .offset-lg-8 {
    margin-left: 66.66667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333%;
  }

  .offset-lg-11 {
    margin-left: 91.66667%;
  }

  .modal-lg {
    max-width: 800px;
  }

  .d-lg-none {
    display: none !important;
  }

  .d-lg-block {
    display: block !important;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }

  .container-fluid {
    max-width: 1400px;
  }
}

.clearfix:after,
.clearfix:before {
  display: table;
  line-height: 0;
  content: "";
}

.clear,
.clearfix:after {
  clear: both;
}

.btn-button.btn-color,
.btn-button.btn-dark,
.btn:hover,
.social:focus,
.social:hover,
.white {
  color: #fff;
}

.left {
  float: left;
}

.owl-carousel.owl-rtl .owl-item,
.pagination,
.right {
  float: right;
}

.bg-light,
.btn-light {
  background-color: #f7f7f7;
}

.bg-dark,
.btn-dark,
.top-bar {
  background-color: #121212;
}

.btn,
.social__text,
.trending-now__label,
.uppercase,
.widget-title {
  text-transform: uppercase;
  letter-spacing: 0.04em;
}

.nocaps {
  text-transform: none;
  letter-spacing: 0;
}

::-moz-selection {
  color: #444;
  background: #fbedc4;
}

::-webkit-selection {
  color: #444;
  background: #fbedc4;
}

::selection {
  color: #444;
  background: #fbedc4;
}

.loader,
.widget_categories li a:hover,
.widget_subcategories li a:hover,
a:focus,
a:hover {
  color: #941b20;
}

.loader-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 99999;
}

.loader,
.loader > div {
  top: 50%;
  left: 50%;
  position: absolute;
}

.loader {
  display: block;
  font-size: 0;
  width: 42px;
  height: 42px;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
}

.btn-lg.btn-button,
.filterbtn {
  height: 46px;
}

.loader > div {
  border-radius: 100%;
  float: none;
  width: 38px;
  height: 38px;
  background: 0;
  border-style: solid;
  border-width: 2px;
  border-right-color: transparent;
  border-left-color: transparent;
  -webkit-animation: 1s cubic-bezier(0.09, 0.57, 0.49, 0.9) infinite
    ball-clip-rotate-pulse-rotate;
  animation: 1s cubic-bezier(0.09, 0.57, 0.49, 0.9) infinite
    ball-clip-rotate-pulse-rotate;
}

@-webkit-keyframes ball-clip-rotate-pulse-rotate {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0);
    transform: translate(-50%, -50%) rotate(0);
  }

  50% {
    -webkit-transform: translate(-50%, -50%) rotate(180deg);
    transform: translate(-50%, -50%) rotate(180deg);
  }

  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes ball-clip-rotate-pulse-rotate {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0);
    transform: translate(-50%, -50%) rotate(0);
  }

  50% {
    -webkit-transform: translate(-50%, -50%) rotate(180deg);
    transform: translate(-50%, -50%) rotate(180deg);
  }

  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-webkit-keyframes ball-clip-rotate-pulse-scale {
  0%,
  100% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
  }

  30% {
    opacity: 0.3;
    -webkit-transform: translate(-50%, -50%) scale(0.15);
    transform: translate(-50%, -50%) scale(0.15);
  }
}

@keyframes ball-clip-rotate-pulse-scale {
  0%,
  100% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
  }

  30% {
    opacity: 0.3;
    -webkit-transform: translate(-50%, -50%) scale(0.15);
    transform: translate(-50%, -50%) scale(0.15);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  color: #121212;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: -0.5px;
}

h1 {
  font-size: 32px;
  letter-spacing: -0.03em;
}

h2 {
  font-size: 26px;
}

h3 {
  font-size: 23px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 1.3rem;
}

h6 {
  font-size: 16px;
}

@media only screen and (max-width: 575px) {
  h1 {
    font-size: 26px;
  }

  h2 {
    font-size: 22px;
  }

  h3 {
    font-size: 18px;
  }

  .text-xs-center {
    text-align: center;
  }
}

@media (max-width: 640px) {
  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 19px;
  }

  h4 {
    font-size: 18px;
  }

  .sidenav-search-icon-btn {
    top: 12px;
    position: absolute;
    right: 50px;
    font-size: 27px;
    color: #fff;
    cursor: pointer;
    background: #941b20;
    padding: 3px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

p {
  font-size: 1.1rem;
  line-height: 1.7;
  font-weight: 400;
  color: #444;
  margin-bottom: 0.8889rem;
}

address {
  font-style: normal;
  margin-bottom: 0;
  font-size: 1rem;
}

.section-buttons a,
hr {
  margin-bottom: 10px;
}

.lead {
  font-size: 18px;
  line-height: 30px;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right;
}

.btn,
.social,
.widget-title,
.widget_media_image {
  text-align: center;
}

blockquote p {
  font-size: 32px;
  line-height: 1.3;
  color: #121212;
}

blockquote cite {
  font-size: 15px;
  color: #bfbfbf;
}

hr {
  margin-top: 10px;
  border-top: 1px solid #e3e4e8;
}

.btn-light:focus,
.btn-stroke:focus,
.btn-white:focus,
.btn:hover {
  border-color: transparent;
  background-color: #121212;
}

@media (min-width: 1280px) {
  .container {
    max-width: 1248px;
  }

  .onlyDesk {
    display: block;
  }
}

.container-semi-fluid {
  padding: 0 50px;
}

.flex-parent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row nowrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.flex-child {
  -webkit-box-flex: 1 0 0;
  -ms-flex: 1 0 0;
  flex: 1 0 0;
}

.no-gutters,
.social:last-child {
  margin-right: 0;
}

.row-2 {
  margin: 0 -1px;
}

.row-2 > aside,
.row-2 > div {
  padding: 0 1px;
}

.row-8 {
  margin: 0 -4px;
}

.row-8 > aside,
.row-8 > div {
  padding: 0 4px;
}

.row-10 {
  margin: 0 -5px;
}

.row-10 > aside,
.row-10 > div {
  padding: 0 5px;
}

.row-16 {
  margin: 0 -8px;
}

.row-16 > aside,
.row-16 > div {
  padding: 0 8px;
}

.row-20 {
  margin: 0 -10px;
}

.row-20 > aside,
.row-20 > div {
  padding: 0 10px;
}

.row-24 {
  margin: 0 -12px;
}

.row-24 > aside,
.row-24 > div {
  padding: 0 12px;
}

.btn {
  display: inline-block;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  color: #fff;
  background-color: #121212;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.loading,
.loading:before {
  position: fixed;
  top: 0;
  left: 0;
}

.btn:focus {
  outline: 0;
  color: #fff;
}

.btn-lg,
.btn-lg.btn-button {
  font-size: 14px;
  padding: 0 16px;
}

.btn-lg span,
.btn-lg.btn-button span {
  line-height: 46px;
}

.btn-sm,
.btn-sm.btn-button {
  font-size: 11px;
  padding: 0 20px;
  line-height: 28px;
}

.btn-sm.btn-button {
  height: 30px;
}

#contact-form .message.error,
.btn-color {
  background-color: #941b20;
}

.btn-color:hover {
  opacity: 0.92;
}

.btn-stroke {
  background-color: transparent;
  border: 1px solid #e3e4e8;
}

.btn i {
  font-size: 10px;
  margin-left: 3px;
  top: -1px;
  line-height: 1;
}

.rounded,
.rounded:before {
  border-radius: 70px;
}

.section-buttons {
  margin-bottom: 50px;
}

.btn-button {
  border: 0;
  margin-bottom: 0;
  width: auto;
}

.btn-button:focus,
.btn-button:hover {
  color: #fff;
  background-color: #121212;
}

.filterbtn {
  border: 1px solid #ccc;
  background: #981115;
  font-size: 1rem;
  padding: 0 20px;
  border-radius: 8px;
  color: #fff;
}

.filterbtn:hover {
  background: #121212;
}

::placeholder {
  color: #121212;
  opacity: 0.7;
}

:-ms-input-placeholder {
  color: #121212;
}

::-ms-input-placeholder {
  color: #121212;
}

input,
select,
textarea {
  height: 46px;
  font-size: 0.8rem;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 8px;
  padding: 0 12px;
  -webkit-transition: border-color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.css-v68sna-control:focus,
.css-v68sna-control:hover {
  border-color: #941b20 !important;
  box-shadow: 0 0 1px #941b20 !important;
}

.css-1fdsijx-ValueContainer,
.css-qbdosj-Input,
.css-v68sna-control {
  height: 46px !important;
  background-color: transparent;
  padding: 0 12px;
  -webkit-transition: border-color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.css-1jqq78o-placeholder {
  padding-top: 5px;
}

.css-1dimb5e-singleValue {
  padding-top: 10px;
}

textarea {
  resize: vertical;
  height: auto;
  display: block;
  padding: 8px 16px;
}

input[type="checkbox"] + label:before,
input[type="checkbox"]:checked + label:after,
input[type="radio"] + label:before,
label {
  display: inline-block;
  vertical-align: middle;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

input:focus,
textarea:focus {
  border-color: #941b20;
  background-color: #fff;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: 0 0 1px #941b20;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #444;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #444;
  opacity: 1;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #444;
  opacity: 1;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #444;
}

select {
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  padding-left: 12px;
  background-image: url(/frontend-bn/img/dropdown.png);
  background-repeat: no-repeat;
  background-position: 100% 50%;
}

select::-ms-expand {
  display: none;
}

label {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 6px;
}

.paging__link--disabled,
.print-no-show,
.react-calendar.hide,
input[type="checkbox"],
input[type="radio"] {
  display: none;
}

input[type="checkbox"] + label:before {
  width: 20px;
  height: 20px;
  content: "";
  font-size: 13px;
  margin: -4px 10px 0 0;
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #dbdce1;
}

.loading {
  z-index: 9999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  bottom: 0;
  right: 0;
}

.loading:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.685);
}

.loading:not(:required) {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: 2s linear infinite spinner;
  -moz-animation: 2s linear infinite spinner;
  -ms-animation: spinner 2000ms infinite linear;
  -o-animation: 2s linear infinite spinner;
  animation: 2s linear infinite spinner;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.content-overlay,
.sidenav {
  z-index: 123;
  position: fixed;
}

.social,
.socials--nobase a {
  line-height: 32px;
  color: #fff;
}

.newsticker-button:hover,
.owl-custom-nav__btn:hover {
  background-color: #941b20;
  color: #fff;
  border-color: transparent;
}

.owl-custom-nav__btn i {
  font-size: 8px;
  margin: 0 3px;
  position: relative;
  top: -1px;
}

.socials {
  overflow: hidden;
  display: flex;
  align-items: baseline;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  flex-direction: row;
}

.social,
.social__text {
  display: inline-block;
  font-size: 13px;
}

.socials--nobase a {
  width: 13px;
  height: auto;
  border: 0;
  margin-right: 15px;
  margin-bottom: 0;
  background-color: transparent;
}

.socials--nobase a:focus,
.socials--nobase a:hover {
  color: #fff;
  background-color: transparent !important;
}

.socials--white-base a {
  background-color: #fff;
  color: #121212;
}

.socials--dark .social-facebook:focus,
.socials--dark .social-facebook:hover {
  color: #39599f !important;
}

.socials--dark .social-twitter:focus,
.socials--dark .social-twitter:hover {
  color: #55acee !important;
}

.socials--dark .social-google-plus:focus,
.socials--dark .social-google-plus:hover {
  color: #de4b39 !important;
}

.socials--dark .social-youtube:focus,
.socials--dark .social-youtube:hover {
  color: #c61d23 !important;
}

.socials--dark .social-snapchat:focus,
.socials--dark .social-snapchat:hover {
  color: #fffb01 !important;
}

.socials--dark .social-linkedin:focus,
.socials--dark .social-linkedin:hover {
  color: #0e76a8 !important;
}

.socials--dark .social-pinterest:focus,
.socials--dark .social-pinterest:hover {
  color: #c8232c !important;
}

.socials--dark .social-instagram:focus,
.socials--dark .social-instagram:hover {
  color: #e1306c !important;
}

.socials--dark .social-rss:focus,
.socials--dark .social-rss:hover {
  color: #ef822a !important;
}

.socials--grey a {
  background-color: #f7f7f7;
  color: #121212;
}

.socials--medium a {
  height: 40px;
  width: 40px;
  line-height: 40px;
  font-size: 16px;
}

.socials--large a {
  height: 46px;
  width: 46px;
  line-height: 46px;
  font-size: 16px;
}

.socials--wide a {
  width: 100%;
  padding: 0 15px;
  margin: 0 0 16px;
}

.socials--rounded a {
  border-radius: 50% !important;
}

.social {
  width: 32px;
  height: 32px;
  margin: 8px;
  padding: 4px;
  border-radius: 6px;
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
}

#back-to-top,
.nav--sticky.offset {
  -webkit-transition: 0.3s ease-in-out;
}

.social__text {
  margin-left: 10px;
}

.social-facebook {
  background-color: #39599f;
}

.social-facebook:hover {
  background-color: #324e8c !important;
  color: #fff !important;
}

.social-twitter {
  background-color: #55acee;
}

.social-twitter:hover {
  background-color: #3ea1ec !important;
  color: #fff !important;
}

.social-google-plus {
  background-color: #de4b39;
}

.social-google-plus:hover {
  background-color: #d93824 !important;
  color: #fff !important;
}

.social-youtube {
  background-color: #c61d23;
}

.social-youtube:hover {
  background-color: #b01a1f !important;
  color: #fff !important;
}

.social-snapchat {
  background-color: #fffb01;
}

.social-snapchat:hover {
  background-color: #e7e300 !important;
  color: #fff !important;
}

.social-linkedin {
  background-color: #0e76a8;
}

.social-linkedin:hover {
  background-color: #0c6590 !important;
  color: #fff !important;
}

.social-pinterest {
  background-color: #c8232c;
}

.social-pinterest:hover {
  background-color: #b21f27 !important;
  color: #fff !important;
}

.social-instagram {
  background-color: #e1306c;
}

.social-instagram:hover {
  background-color: #d81f5e !important;
  color: #fff !important;
}

.social-rss {
  background-color: #ef822a;
}

.social-rss:hover {
  background-color: #ed7412 !important;
  color: #fff !important;
}

.nav--sticky {
  border-bottom: 1px solid #981115;
}

.nav--sticky.offset {
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
  transition: 0.3s ease-in-out;
}

.nav--sticky.scrolling {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.nav--sticky.sticky {
  position: fixed;
  background-color: transparent;
  visibility: hidden;
  opacity: 0;
  left: 0;
  right: 0;
  top: 0;
  -webkit-box-shadow: 0 2px 2px 2px #000 0 1px 2px 2px #000 0 2px 2px 2px #000;
  box-shadow: 0 2px 2px 2px #000 0 1px 2px 2px #000 0 2px 2px 2px #000;
  z-index: 120;
}

.content-overlay--is-visible,
.nav--sticky.sticky.scrolling {
  opacity: 1;
  visibility: visible;
}

.nav--sticky.sticky .nav__menu > li > a {
  line-height: 20px;
}

.nav--sticky.sticky .logo-wrap a {
  height: 48px;
}

.widget {
  background-color: #fff;
  padding: 0;
  border: 1px solid #98111554;
  border-radius: 6px;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.sidebar .widget_mc4wp_form_widget {
  padding: 32px !important;
}

@media only screen and (min-width: 1200px) {
  .blog__content--right,
  .sidebar--right {
    float: right;
  }

  .sidebar--right {
    padding-left: 35px;
  }

  .onlyDesk {
    display: block;
  }
}

.widget-title {
  position: relative;
  width: 100%;
  font-size: 1.4rem;
  color: #fff;
  padding: 10px;
  background-color: #b71d20;
  background-image: linear-gradient(
      45deg,
      #a21219fc 25%,
      transparent 25%,
      transparent 75%,
      #a21219fc 75%
    ),
    linear-gradient(
      45deg,
      #a21219fc 25%,
      transparent 25%,
      transparent 75%,
      #a21219fc 75%
    );
  background-size: 3px 3px;
  background-position: 0 0, 2px 2px;
}

.widget-slider-box {
  padding: 10px;
}

.widget .sub-menu {
  padding-left: 15px;
  padding-top: 5px;
}

.widget .sub-menu li {
  padding: 5px 0 !important;
}

.widget .children {
  padding-top: 10px;
  padding-left: 15px;
  margin-top: 10px;
}

.widget_media_image {
  padding: 0 !important;
  background-color: transparent !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 0 !important;
}

.search-button,
.widget-search-button {
  position: absolute;
  top: 0;
  right: 0;
  width: 46px;
  height: 46px;
  line-height: 46px;
  padding: 0 !important;
  border: 0;
  vertical-align: middle;
}

.search-button i,
.widget-search-button i {
  font-size: 18px;
  margin: 0;
  top: 3px;
}

.widget_categories li {
  padding: 5px 15px;
  color: #121212;
}

.pt-0,
.widget_categories li:first-child,
.widget_nav_menu li:first-child {
  padding-top: 0;
}

.pb-0,
.widget_categories li:last-child,
.widget_nav_menu li:last-child {
  padding-bottom: 0;
}

.widget_categories li a {
  color: #121212;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.2;
}

.widget_categories li a:before,
.widget_subcategories li a:before {
  content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/></svg>');
  font-size: 10px;
  color: #941b20;
  margin-right: 8px;
  line-height: 1;
  vertical-align: middle;
}

.widget_subcategories li {
  padding: 0 0 5px 15px;
  color: #555;
  line-height: 24px;
}

.widget_subcategories li a {
  color: #555;
  font-size: 1rem;
}

.categories-count {
  float: right;
  background-color: #f7f7f7;
  display: inline-block;
  padding: 0 11px;
  height: 24px;
  border-radius: 5px;
}

.widget_nav_menu li {
  padding: 4px 0;
}

.pagination {
  margin-top: 0;
  margin-bottom: 20px;
  text-align: right;
}

.newsticker-button,
.trending-now__label {
  float: left;
  line-height: 36px;
  height: 36px;
}

.pagination__page {
  font-size: 15px;
  display: inline-block;
  width: 30px;
  height: 32px;
  line-height: 32px;
  margin-right: 2px;
  text-align: center;
  color: #121212;
  background-color: #fff;
  vertical-align: middle;
}

.pagination__page--current,
.pagination__page:not(span):hover {
  background-color: #981115;
  border-color: transparent;
  color: #fff;
}

.pagination__icon {
  font-size: 12px;
}

.page-title {
  font-size: 38px;
  margin-bottom: 24px;
}

.gmap {
  width: 100%;
  height: 480px;
  margin-bottom: 40px;
}

.contact-items,
.mb-24 {
  margin-bottom: 24px;
}

.contact-item {
  padding: 3px 0;
}

.contact-email,
.contact-name,
.contact-subject {
  max-width: 266px;
}

.page-404-number {
  font-size: 80px;
  margin-bottom: 0;
}

.logo {
  line-height: 1;
}

.logo-holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#back-to-top:hover i,
.nav--colored .nav__menu > li > a,
.nav--colored .nav__menu > li > a:hover,
.nav--colored .nav__right a:focus,
.nav--colored .nav__right a:hover,
.nav--colored .nav__search-trigger,
.top-bar .social {
  color: #fff;
}

.top-menu li {
  display: inline-block;
  font-size: 14px;
}

.top-menu li:first-child a {
  padding-left: 0;
}

.top-menu li:last-child a {
  padding-right: 0;
}

.top-menu a {
  display: block;
  padding: 0 10px;
  line-height: 32px;
  color: #fff;
}

.alternateLinkClass:hover,
.read-more:hover,
.sidenav__menu--is-open > a:hover,
.sidenav__menu-dropdown .sidenav__menu-toggle,
.sidenav__menu-dropdown a,
.sidenav__menu-dropdown:hover {
  color: #121212;
}

.sidenav {
  background-color: #fff;
  top: 0;
  bottom: 0;
  left: 0;
  width: 320px;
  overflow-y: auto;
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.55, 0, 0.1, 1);
  transition: transform 0.5s cubic-bezier(0.55, 0, 0.1, 1),
    -webkit-transform 0.5s cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transform: translateX(-320px);
  transform: translateX(-320px);
}

.sidenav--is-open {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.content-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.3s cubic-bezier(0.16, 0.36, 0, 0.98);
  transition: 0.3s cubic-bezier(0.16, 0.36, 0, 0.98);
  background-color: rgba(0, 0, 0, 0.5);
}

.sidenav__menu-container {
  margin-top: 52px;
}

.custom-slider-btns,
.sidenav__menu ul {
  padding: 0;
}

.sidenav__menu li {
  position: relative;
  border-bottom: 1px solid #e3e4e8;
  font-size: 14px;
}

.sidenav__menu li:last-child {
  border-bottom: 0;
}

.sidenav__menu-dropdown {
  overflow: hidden;
  display: none;
  background-color: #f7f7f7;
}

.sidenav__menu-dropdown li {
  border-color: #dbdce1;
  padding-left: 10px;
}

.sidenav__menu-dropdown li:first-child,
.top-divider {
  border-top: 1px solid #e3e4e8;
}

.sidenav__menu-toggle {
  position: absolute;
  top: 0;
  right: 5px;
  background-color: transparent;
  border: 0;
  color: #121212;
  font-size: 12px;
  padding: 0;
  width: 46px;
  height: 46px;
  text-align: center;
}

.newsticker-button:focus,
.sidenav__menu-toggle:focus {
  outline: 0;
}

.sidenav__menu--is-open > a {
  background-color: #f7f7f7;
  color: #121212;
}

.sidenav__menu--is-open > .sidenav__menu-toggle {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  color: #121212;
}

.category-section-layout .section-title .sub-title a:hover,
.category-section-layout .section-title .title:hover,
.sidenav__menu--is-open > .sidenav__menu-toggle:hover,
.single-post-layout .single-news-tags li a:hover,
.single-post-layout .single-post-categories .cateries-list a:hover {
  color: #121212;
}

.sidenav__menu-url {
  width: 100%;
  display: block;
  color: #121212;
  padding: 12px 22px;
  font-size: 15px;
  font-weight: 600;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}

.sidenav__menu-url:focus,
.sidenav__menu-url:hover {
  color: #941b20;
}

.sidenav__close {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 1;
}

.sidenav__close-button {
  padding: 0;
  background: 0;
  border: 0;
  color: #121212;
  width: 24px;
  height: 24px;
}

.bottom-gradient,
.full-overlay {
  position: absolute;
  width: 100%;
  z-index: 1;
}

.alternateLinkClass,
.category-section-layout .title:hover,
.grid-layout .grid-title-light:hover,
.grid-layout .grid-title-small:hover,
.grid-layout .grid-title:hover,
.sidenav__close-button:hover,
.single-post-layout .desk-title:hover,
.single-post-layout .single-news-tags li a,
.single-post-layout .title:hover {
  color: #941b20;
}

.sidenav__close-icon {
  font-size: 22px;
  line-height: 24px;
}

.sidenav__socials {
  padding: 0 22px;
  margin-top: 20px;
}

.nav--colored .nav__holder {
  background-color: #941b20;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.nav--colored .nav-icon-toggle:hover .nav-icon-toggle__inner,
.nav--colored .nav-icon-toggle:hover .nav-icon-toggle__inner:after,
.nav--colored .nav-icon-toggle:hover .nav-icon-toggle__inner:before,
.nav--colored .nav-icon-toggle__inner,
.nav--colored .nav-icon-toggle__inner:after,
.nav--colored .nav-icon-toggle__inner:before {
  background-color: #fff;
}

#back-to-top {
  display: block;
  z-index: 100;
  width: 34px;
  height: 34px;
  text-align: center;
  font-size: 12px;
  position: fixed;
  bottom: 2px;
  right: 15px;
  line-height: 32px;
  background-color: #981115;
  -webkit-box-shadow: 1px 1.732px 12px 0 rgba(0, 0, 0, 0.03);
  box-shadow: 1px 1.732px 12px 0 rgba(0, 0, 0, 0.03);
  transition: 0.3s ease-in-out;
}

#back-to-top i {
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

#back-to-top a {
  display: block;
  color: #fff;
  margin-top: 5px;
}

#back-to-top.show {
  bottom: 20px;
}

#back-to-top:hover {
  background-color: #2d1212;
  border-color: transparent;
}

.m-0 {
  margin: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.mt-8 {
  margin-top: 8px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-48 {
  margin-top: 48px;
}

.mt-56 {
  margin-top: 56px;
}

.mt-64 {
  margin-top: 64px;
}

.mt-72 {
  margin-top: 72px;
}

.mt-80 {
  margin-top: 80px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mb-48 {
  margin-bottom: 48px;
}

.mb-56 {
  margin-bottom: 56px;
}

.mb-64 {
  margin-bottom: 64px;
}

.mb-72 {
  margin-bottom: 72px;
}

.mb-80 {
  margin-bottom: 80px;
}

.pt-8 {
  padding-top: 8px;
}

.pt-16 {
  padding-top: 16px;
}

.pt-24 {
  padding-top: 24px;
}

.pt-32 {
  padding-top: 32px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-48 {
  padding-top: 48px;
}

.pt-56 {
  padding-top: 56px;
}

.pt-64 {
  padding-top: 64px;
}

.pt-72 {
  padding-top: 72px;
}

.pt-80 {
  padding-top: 80px;
}

.pb-8 {
  padding-bottom: 8px;
}

.pb-16 {
  padding-bottom: 16px;
}

.pb-24 {
  padding-bottom: 24px;
}

.pb-32 {
  padding-bottom: 32px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-48 {
  padding-bottom: 48px;
}

.pb-56 {
  padding-bottom: 56px;
}

.pb-64 {
  padding-bottom: 64px;
}

.pb-72 {
  padding-bottom: 72px;
}

.pb-80 {
  padding-bottom: 80px;
}

.full-overlay {
  content: "";
  display: block;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.bottom-gradient {
  height: 50%;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(0, 0, 0, 0.7)),
    to(transparent)
  );
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0, transparent 100%);
}

.bottom-divider {
  border-bottom: 1px solid #e3e4e8;
}

.trending-now {
  background-color: #fff;
  position: relative;
  margin-top: 24px;
  height: 36px;
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.trending-now__label {
  background-color: #b71d20;
  background-image: linear-gradient(
      45deg,
      #a21219fc 25%,
      transparent 25%,
      transparent 75%,
      #a21219fc 75%
    ),
    linear-gradient(
      45deg,
      #a21219fc 25%,
      transparent 25%,
      transparent 75%,
      #a21219fc 75%
    );
  background-size: 3px 3px;
  background-position: 0 0, 2px 2px;
  font-weight: 600;
  display: inline-block;
  color: #fff;
  padding: 0 16px;
  font-size: 14px;
}

.trending-now__text {
  margin-left: 5px;
}

.trending-now--1 {
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-top: 0;
  background: #121212;
}

.newsticker__item {
  line-height: 36px;
  padding-right: 15px;
}

.blak-bg_setion-title-color,
.newsticker__item-url,
.title-color-white {
  color: #fff;
  padding-right: 15px;
}

.newsticker__item-url:hover,
.paging__link--active a,
.react-calendar__month-view__days__day--weekend {
  color: #941b20 !important;
}

.newsticker-buttons {
  position: absolute;
  top: 0;
  right: 0;
}

.newsticker-button {
  background-color: transparent;
  padding: 0;
  width: 36px;
  text-align: center;
  font-size: 12px;
  color: #fff;
  border: 0;
}

.newsticker-button:first-child {
  border-right: 1px solid #e3e4e8;
}

@media only screen and (min-width: 992px) {
  .post-list-small__img-holder {
    width: 50px;
  }

  .post-list-small__entry-title {
    font-size: 0.9rem;
  }

  .post-list-small__img-holder--2 {
    width: 200px;
  }

  .nopadding {
    padding: 0;
  }

  .nopaddingleft {
    padding: 0 5px 0 0;
  }

  .nopaddingright {
    padding: 0 0 0 5px;
  }

  .nopaddingrightside {
    padding: 0 15px;
  }

  .margintop15 {
    margin-top: 15px !important;
  }

  .marginbottom15 {
    margin-bottom: 15px !important;
  }

  .thumb--size-1 {
    height: 182px;
    max-height: 182px;
    padding: 10px 0 0;
  }
}

.custom-loader,
.paging {
  display: flex;
  justify-content: center;
}

.header-top {
  background: #12142b;
}

.blak-bg_setion {
  background: #121212;
  padding: 15px 0;
}

.active {
  color: #121212 !important;
}

.text-left {
  text-align: left !important;
}

.black_desc {
  color: #c8c4c4 !important;
}

.nav_menu_top li a {
  font-size: 1.1rem;
}

.langText {
  font-size: 1.2rem;
  margin-top: 14px;
}

.custom-loader {
  text-align: center;
  width: 100%;
  margin: 15px;
}

.paging {
  margin: 15px 0;
  list-style-type: none;
}

.paging li a {
  float: left;
  padding: 8px 15px;
  border: 1px solid #a3a3a3;
}

.section-subtitle {
  position: relative;
  font-size: 1.1rem;
  bottom: 0;
  padding: 10px;
  color: #fff;
  font-weight: 400;
}

.image-caption,
figcaption {
  font-style: italic;
  font-size: 14px;
  float: left;
}

.react-calendar {
  position: absolute;
  background: #fff !important;
  z-index: 999;
}

.image-caption {
  color: #363636;
  text-align: left;
  width: 75%;
}

.react-calendar__tile--now {
  background: #941b20 !important;
  color: #fff;
}

.pagination {
  display: flex;
  padding-left: 0;
  border-radius: 5px;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #981115;
  background-color: #fff;
  border: 1px solid #ccced2;
}

.page-item:first-child .page-link,
.pagination-lg .page-item:first-child .page-link,
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.page-link:hover {
  z-index: 2;
  color: #981115;
  background-color: #e9ecef;
  border-color: #ccced2;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(51, 120, 255, 0.25);
}

.page-item:last-child .page-link,
.pagination-lg .page-item:last-child .page-link,
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #981115;
  border-color: #981115;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #ccced2;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.grid-layout .grid-title,
.grid-layout .grid-title-light {
  font-size: 1.2rem;
  line-height: 1.3;
  font-weight: 600;
  text-align: left;
}

.required {
  color: red;
}

.checkbox-input-custom-design input {
  display: block;
  float: left;
  height: 20px;
  width: 30px;
  border: none;
  box-shadow: none;
}

.checkbox-input-custom-design span {
  color: #000;
  font-size: 1rem;
}

.form-link-custom {
  font-size: 1rem;
  color: #981115;
}

.form-link-custom:hover {
  font-size: 1rem;
  color: #000;
}

.btn-primary-submit {
  background: #981115;
  color: #fff;
  border-radius: 6px;
  border: 1px solid #981115;
  text-transform: capitalize;
}

.btn-primary-light,
.btn-primary:hover {
  background: #121212;
}

.btn-primary {
  background: #121212;
  color: #fff;
  border-radius: 6px;
  border: 1px solid #121212;
}

.btn-primary-light,
.btn-primary:hover {
  background: #981115;
}

.btn-primary-light {
  color: #fff;
  border-radius: 6px;
  border: 1px solid #981115;
}

.btn-primary-light:hover {
  background: #d332326d;
}

.Demo__some-network {
  vertical-align: top;
  display: inline-block;
  margin-right: 10px;
  text-align: center;
}

.Demo__some-network__share-count {
  display: inline-flex;
  justify-content: center;
  white-space: nowrap;
  overflow: visible;
  width: 0;
  margin-top: 3px;
  font-size: 12px;
}

.Demo__some-network__share-button:hover:not(:active) {
  opacity: 0.75;
}

.Demo__some-network__custom-icon {
  width: 32px;
  height: 32px;
}

.activeplaylist {
  font-weight: 600 !important;
  font-size: 1.2rem !important;
}

.descriptionlinkclass {
  text-decoration: underline;
  font-style: italic;
  font-weight: 600;
  color: #941b20;
}

.ReactModal__Overlay,
.ReactModal__Overlay--after-open {
  z-index: 999 !important;
}

.yvideo-box {
  max-width: 712px;
  margin: 0 auto;
}

@media only screen and (max-width: 767px) {
  .text-sm-center {
    text-align: center;
  }

  .smallScreenHide {
    display: none !important;
  }

  .container-semi-fluid {
    padding: 0 15px;
  }

  .section-title {
    float: none;
  }
}

.all-posts-url {
  float: right;
  color: #bfbfbf;
  font-size: 11px;
  line-height: 28px;
}

.content-box {
  background-color: #fff;
  padding: 0;
}

.showbigscreen {
  display: inline-grid;
  grid-template-columns: 115px 100vw;
}

.newsticker__item,
.trending-now__label {
  display: inline-block;
}

@media only screen and (max-width: 991px) {
  .video-playlist {
    display: block;
  }

  .showbigscreen,
  .video-playlist__content,
  .video-playlist__list {
    width: 100%;
  }

  .nopadding,
  .nopaddingleft,
  .nopaddingright,
  .nopaddingrightside {
    padding: 7px;
  }

  .nomargin {
    margin: 0 !important;
  }

  .thumb--size-1 {
    height: 210px;
    max-height: 210px;
    padding: 10px 0 0;
  }

  .hidesmallscreen {
    display: none;
  }
}

@media only screen and (min-width: 992px) {
  .content-box--top-offset {
    margin-top: -232px;
  }

  .logo img {
    height: 50px !important;
    width: 100px !important;
    padding-left: 0;
    margin-top: 10px;
  }

  .showbigscreen {
    width: 80%;
    float: left;
  }

  .hidesmallscreen {
    display: block;
    width: 20%;
    float: left;
  }
}

@media only screen and (max-width: 575px) {
  .social__text {
    display: none;
  }

  .page-title {
    font-size: 24px;
  }

  .page-404-number {
    font-size: 50px;
  }

  .content-box {
    padding: 24px 0px;
  }

  .nav--sticky {
    height: 65px;
  }
}

.content-box--pt-108 {
  padding-top: 108px;
}

@media only screen and (max-width: 575px) {
  .content-box--pt-108 {
    padding-top: 60px;
  }
}

.mainHeader {
  padding: 12px 18px;
  background: #3a0ffd;
  color: #fff;
  text-align: center;
}

.main-block {
  padding: 16px;
  max-width: 900px;
  margin: 0 auto;
  font-size: 18px;
}

.fixedTop {
  width: 100%;
  padding: 12px 18px;
  position: fixed;
  top: 0;
  background: #fb04eb;
}

.fixedTop + .main-block {
  padding-top: 102px;
}

@media only screen and (max-width: 991px) {
  .nav__header {
    height: 48px;
  }

  .nav__wrap {
    text-align: left;
  }

  .nav-icon-toggle {
    margin-top: 0 !important;
  }

  .logo {
    position: absolute;
    left: 75px;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 40px;
    line-height: 48px;
    text-align: center;
  }

  .logo img {
    height: 55px !important;
    width: 125px !important;
    padding-left: 30px !important;
  }

  .onlyDesk {
    display: none;
  }

  .nav--sticky {
    height: 65px;
  }
}

@media only screen and (max-width: 775px) {
  .logo {
    position: absolute;
    left: 100px;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 25px;
    line-height: 48px;
    text-align: center;
  }
}

@media only screen and (min-width: 1024px) {
  .logo {
    position: absolute;
    left: 100px;
    top: 40%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 40px;
    line-height: 45px;
    text-align: center;
  }

  .logo img {
    padding-left: 5px !important;
    height: 55px !important;
    width: 125px !important;
    margin-top: 0;
  }
}

@media only screen and (max-width: 776px) {
  .logo {
    position: absolute;
    left: 100px;
    top: 40%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 40px;
    line-height: 45px;
    text-align: center;
  }

  .logo img {
    padding-left: 5px !important;
    height: 55px !important;
    width: 125px !important;
    margin-top: 8px;
  }
}

figcaption {
  color: #2d2c2c;
  text-align: center;
  width: 100%;
  padding: 5px;
  margin-bottom: 15px;
}

.custom-slider-left-btn,
.custom-slider-right-btn {
  color: #d6d6d6 !important;
  padding: 2px 15px;
  font-size: 16px;
  background: #981115 !important;
}

.custom-slider-left-btn {
  margin-right: 5px;
  border: 0;
  border-radius: 4px;
}

.custom-slider-left-btn i,
.custom-slider-right-btn i {
  margin-top: 5px;
}

.custom-slider-right-btn {
  margin-left: 5px;
  border: 0;
  border-radius: 4px;
}

.custom-slider-left-btn:hover,
.custom-slider-right-btn:hover {
  background: #98111589 !important;
}

ul.descriptionlinkclassul {
  list-style-type: square;
  padding-left: 30px;
}

.page-image {
  cursor: pointer;
  width: 100%;
  background: #e9e7e770;
  border-radius: 5px;
  margin-bottom: 10px;
  object-position: center;
  border: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.page-image-small,
.page-image-small-30 {
  float: left !important;
  border-radius: 5px;
}

.page-image-small {
  width: 40% !important;
  margin: 0 10px 0 0 !important;
}

.page-image-small-30 {
  width: 30% !important;
  margin: 0 5px 0 0 !important;
}

.grid-layout,
.grid-layout .card {
  margin-bottom: 15px;
  width: 100%;
  position: relative;
}

.grid-layout {
  background: 0;
  display: flex;
  flex-flow: row wrap;
  float: left;
}

.grid-layout .grid-title {
  color: #121212;
}

.grid-layout .grid-title-light {
  color: #e7e7e7;
  cursor: pointer;
}

.grid-layout .grid-title-small {
  font-size: 1.1rem;
  line-height: 1.1;
  text-align: left;
  font-weight: 400;
  color: #121212;
}

.grid-layout .card {
  border-radius: 12px;
  padding: 15px;
  border: 1px solid #80191773;
  cursor: pointer;
}

.grid-layout .grid-desc {
  font-size: 1.1rem;
  line-height: 1.6;
  font-weight: 400;
  margin: 6px 0;
  color: #444;
}

.grid-layout .grid-desc-small {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #444;
}

.grid-layout .grid-desc-light {
  font-size: 1.1rem;
  line-height: 1.6;
  margin: 6px 0;
  color: #bfbfbf;
}

.grid-layout .grid-small {
  font-size: 0.8rem;
  color: #941b20;
  font-weight: 600;
  display: block;
  margin: 0;
}

.grid-layout .grid-small-light {
  font-size: 0.8rem;
  color: #d7d7d7;
  font-weight: 600;
  display: block;
  margin: 0;
}

.category-section-layout {
  background: 0;
  position: relative;
  width: 100%;
}

.category-section-layout .section-title {
  float: left;
  width: 100%;
  margin-bottom: 15px;
  border-radius: 6px;
  position: relative;
  font-size: 1.4rem;
  color: #fff;
  font-weight: 600;
  display: inline-block;
  background-color: #b71d20;
  background-image: linear-gradient(
      45deg,
      #a21219fc 25%,
      transparent 25%,
      transparent 75%,
      #a21219fc 75%
    ),
    linear-gradient(
      45deg,
      #a21219fc 25%,
      transparent 25%,
      transparent 75%,
      #a21219fc 75%
    );
  background-size: 3px 3px;
  background-position: 0 0, 2px 2px;
}

.category-section-layout .section-title .title {
  position: relative;
  float: left;
  margin-bottom: 0;
  font-size: 1.4rem;
  color: #fff;
  font-weight: 600;
  display: inline-block;
  padding: 15px;
}

.category-section-layout .section-title .sub-title {
  margin-top: 4px;
  float: left;
}

.category-section-layout .section-title .sub-title a {
  position: relative;
  font-size: 1rem;
  bottom: 0;
  padding: 10px;
  color: #fff;
  font-weight: 600;
}

.category-section-layout .section-title .sub-title a svg {
  position: relative;
  padding-top: 5px;
}

.category-section-layout .category-link {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #60605e;
  font-size: 0.8rem;
  padding: 5px 20px;
  margin-left: 15px;
  z-index: 2;
  color: #fff;
  margin-bottom: 0;
  display: inline-block;
  border-top-left-radius: 5px;
}

.category-section-layout .title,
.category-section-layout .title-light {
  font-size: 1.2rem;
  line-height: 1.3;
  text-align: left;
  font-weight: 600;
}

.category-section-layout .desc {
  font-size: 1.1rem;
  line-height: 1.7;
  margin: 6px 0;
}

.category-section-layout .small {
  width: 100%;
  color: #941b20;
  font-size: 0.8rem;
  font-weight: 600;
  margin: 0;
}

.category-section-layout .readmore {
  font-size: 1.1rem;
  margin: 15px 0;
  display: block;
}

.single-post-layout .single-post-categories {
  width: 100%;
  display: block;
}

.single-post-layout .single-post-categories .cateries-list {
  float: left;
  list-style-type: disc;
  padding-right: 20px;
  margin-left: 20px;
  color: #941b20;
}

.single-post-layout .single-post-categories .cateries-list a {
  color: #941b20;
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
}

.single-post-layout .title {
  font-size: 28px;
  color: #121212;
  width: 100%;
  cursor: pointer;
  display: inline-block;
}

.single-post-layout .desk-logo {
  height: 60px;
  width: 60px;
  float: left;
  margin-right: 15px;
  cursor: pointer;
}

.single-post-layout .desk-title {
  line-height: 1.6px;
  margin-top: 18px;
  font-size: 1.2rem;
  font-weight: 400;
  cursor: pointer;
}

.single-post-layout .desk-time {
  display: inline-block;
  font-size: 0.8rem;
  font-weight: 400;
  text-transform: uppercase;
  color: #121212;
}

.single-post-layout .desk-time i {
  margin-right: 5px;
}

.single-post-layout .image-caption,
.single-post-layout figcaption {
  color: #363636;
  text-align: left;
  width: 75%;
  font-style: italic;
  font-size: 14px;
  float: left;
  margin-bottom: 30px;
}

.comments h1,
.comments p {
  font-size: 1rem;
  color: #121212;
}

.single-post-layout .view-comment {
  color: #363636;
  font-style: italic;
  font-size: 14px;
  margin-bottom: 30px;
  float: right;
}

.single-post-layout .view-comment li {
  float: left;
  padding-right: 10px;
}

.single-post-layout .single-news-tags li {
  font-size: 0.8rem;
  text-transform: uppercase;
  margin-bottom: 3px;
  font-weight: 600;
  float: left;
  padding-right: 10px;
}

.comments img {
  height: 50px;
  width: 50px;
  float: left;
  padding-right: 15px;
}

.comments h1 {
  margin: 0;
  font-weight: 600;
}

.comments h1 small {
  margin: 0;
  font-size: 0.7rem;
  color: #941b20;
  font-weight: 400;
  padding: 0 10px;
}

.comments p {
  font-weight: 400;
  margin-bottom: 20px;
}

.sidebar-active {
  color: #941b20 !important;
  font-weight: 600 !important;
}

.sidebar-sub-active {
  color: #941b20 !important;
  font-weight: 600 !important;
}

.sidebar-active a {
  color: #941b20 !important;
  font-weight: 600 !important;
}

.button-1 {
  background-color: #98111554;
  border-radius: 3px;
  border: 1px solid #98111554;
  box-shadow: #98111554 0 1px 0 0 inset;
  box-sizing: border-box;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", "Liberation Sans",
    sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.15385;
  margin: 5px;
  outline: 0;
  padding: 8px 0.8em;
  position: relative;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: 90%;
}

.button-1:focus,
.button-1:hover {
  background-color: #bc242954;
  color: #121212;
}

.headerFixed {
  position: fixed;
  z-index: 120;
  width: 100%;
}

.headerFixedPadding {
  padding-top: 100px;
}

@media (max-width: 575px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 825px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-xl > .table-bordered {
    border: 0;
  }

  .category-section-layout .section-title .sub-title {
    width: 100%;
  }

  .category-section-layout .section-title .sub-title a {
    padding: 15px;
    width: 50%;
    float: left;
  }
}

@media (min-width: 374px) {
  .banner-buttom-adds {
    padding: 15px;
    margin-bottom: 15px;
  }

  .page-image-small {
    width: 50% !important;
    float: left !important;
    border-radius: 5px;
    margin: 0 5px 10px 0 !important;
  }

  .youtubeicon {
    position: absolute;
    left: 20%;
    top: 22%;
    height: 40px;
    width: 40px;
    cursor: pointer;
  }

  .headerFixedPadding {
    padding-top: 100px;
  }
}

@media (min-width: 575px) {
  .banner-buttom-adds {
    padding: 0 7px 0 7px;
    margin-bottom: 15px;
  }

  .page-image-small {
    width: 100% !important;
    float: left !important;
    border-radius: 5px;
    font-size: 1rem !important;
    margin: 0 5px 10px 0 !important;
  }

  .grid-title-small {
    font-size: 0.9rem !important;
  }

  .grid-small {
    font-size: 0.7rem !important;
  }

  .youtubeicon {
    position: absolute;
    left: 35%;
    top: 8%;
    height: 40px;
    width: 40px;
    cursor: pointer;
  }

  .headerFixedPadding {
    padding-top: 100px;
  }
}

@media (min-width: 768px) {
  .page-image-small {
    width: 100% !important;
    float: left !important;
    border-radius: 5px;
    margin: 0 5px 10px 0 !important;
  }

  .grid-title-small {
    font-size: 1rem !important;
  }

  .grid-small {
    font-size: 0.7rem !important;
  }

  .youtubeicon {
    position: absolute;
    left: 40%;
    top: 20%;
    height: 40px;
    width: 40px;
    cursor: pointer;
  }

  .headerFixedPadding {
    padding-top: 100px;
  }

  .category-section-layout .section-title .sub-title {
    width: 100%;
  }

  .category-section-layout .section-title .sub-title a {
    padding: 15px;
    width: 25%;
    float: left;
  }
}

@media (min-width: 992px) {
  .page-image-small {
    width: 50% !important;
    float: left !important;
    border-radius: 5px;
    margin: 0 5px 10px 0 !important;
  }

  .youtubeicon {
    position: absolute;
    left: 19%;
    top: 12%;
    height: 40px;
    width: 40px;
    cursor: pointer;
  }

  .grid-title-small {
    font-size: 1.1rem !important;
  }

  .grid-small {
    font-size: 0.8rem !important;
  }

  .headerFixedPadding {
    padding-top: 170px;
  }

  .category-section-layout .section-title .sub-title {
    width: auto;
  }

  .category-section-layout .section-title .sub-title a {
    padding: 15px;
    width: auto;
    float: left;
  }
}

@media (min-width: 1000px) {
  .banner-buttom-adds {
    padding: 0 15px 0 0;
    margin-bottom: 15px;
  }

  .headerFixedPadding {
    padding-top: 165px;
  }
}

@media (min-width: 1200px) {
  .page-image-small {
    width: 45% !important;
    float: left !important;
    border-radius: 5px;
    margin: 0 10px 0 0 !important;
  }

  .youtubeicon {
    position: absolute;
    left: 18%;
    top: 12%;
    height: 40px;
    width: 40px;
    cursor: pointer;
  }

  .headerFixedPadding {
    padding-top: 170px;
  }
}

@media (min-width: 1275px) {
  .headerFixedPadding {
    padding-top: 125px;
  }
}

.overlay-div {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.685);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 122;
  display: block;
  overflow-y: hidden;
  overflow-x: hidden;
}

.sidebar-category {
  max-height: 320px;
  overflow-y: auto;
}

.ReactModal__Overlay,
.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.685) !important;
}

@media print {
  html,
  body {
    margin: 2mm !important;
    height: auto;
    padding: 0 !important;
    background: #fff;
  }

  @page {
    margin: 2rem;
  }

  .page-image {
    width: 100% !important;
  }

  .no-print,
  .no-print * {
    display: none !important;
  }

  .print-no-show {
    display: block !important;
  }

  .desk-logo {
    height: 75px;
    float: left;
    width: 108px;
  }
}

input,
select,
textarea {
  border-radius: 4px !important;
}

.css-1nmdiq5-menu {
  z-index: 2 !important;
}

/* Shared */
.loginBtn {
  box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  margin: 0.2em;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #fff;
}

.loginBtn:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}

.loginBtn:focus {
  outline: none;
}

.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}

/* x twittter */
.loginBtn--twitter {
  background-color: #0c0c0c;
  background-image: linear-gradient(#4c69ba, #3b55a0);
  /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
  text-shadow: 0 -1px 0 #151516;
}

.loginBtn--twitter:before {
  border-right: #121213 1px solid;
  background: url("/icon_x.png") 6px 6px no-repeat;
}

.loginBtn--twitter:hover,
.loginBtn--twitter:focus {
  background-color: #0a0a0a;
  background-image: linear-gradient(#232324, #4e5055);
}

/* Facebook */
.loginBtn--facebook {
  background-color: #4c69ba;
  background-image: linear-gradient(#4c69ba, #3b55a0);
  /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
  text-shadow: 0 -1px 0 #354c8c;
}

.loginBtn--facebook:before {
  border-right: #364e92 1px solid;
  background: url("/icon_facebook.png") 6px 6px no-repeat;
}

.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
  background-color: #5b7bd5;
  background-image: linear-gradient(#5b7bd5, #4864b1);
}

/* Google */
.loginBtn--google {
  /*font-family: "Roboto", Roboto, arial, sans-serif;*/
  background: #dd4b39;
}

.loginBtn--google:before {
  border-right: #bb3f30 1px solid;
  background: url("/icon_google.png") 6px 6px no-repeat;
}

.loginBtn--google:hover,
.loginBtn--google:focus {
  background: #e74b37;
}
.masonry-layout {
  columns: 3;
  gap: 16px;
}
@media (max-width: 1024px) {
  .masonry-layout {
    columns: 2;
  }
}

@media (max-width: 768px) {
  .masonry-layout {
    columns: 1;
  }
}

.radio-group {
  display: flex;
  gap: 30px;
}

.radio-item {
  display: flex;
  align-items: center;
}

.radio-item input[type="radio"] {
  display: inline !important;
  width: 25px;
  height: 25px;
}

.radio-item label {
  margin-top: 13px;
  margin-left: 8px;
}
.live-tv-logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.live-tv-div {
  display: none;
  width: 120px;
}
.live-tv-btn {
  display: inline-block;
  background: #000;
  color: white;
  font-weight: 900;
  font-size: 16px;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  letter-spacing: 1px;
  position: relative;
  overflow: hidden;
}
.live-tv-btn:hover {
  color: #dfb9b9;
}
.live-tv-btn:focus {
  color: #00bcd4;
}

@media (max-width: 980px) {
  .live-tv-div {
    display: block;
    float: right;
    margin-top: 10px;
    margin-right: -20px;
  }
}
@media (max-width: 765px) {
  .live-tv-div {
    margin-top: -35px;
  }
}
